<template>
    <vs-chip class="ag-grid-cell-chip" :color="chipColor(params)">
        <span>{{ organizationTypeString(params) }}</span>
    </vs-chip>
</template>

<script>
export default {
    name: 'CellRendererStatus',
    computed: {
        chipColor() {
            return value => {
                if (value.data.enabled === false) {
                    return "danger"
                } else if (value.data.enabled === true) {
                    return "success"
                }
            }
        },
        organizationTypeString() {
            return value => {
                if (value.data.enabled === false) {
                    return this.$t('employeeStatus.offWork')
                } else if (value.data.enabled === true) {
                    return this.$t('employeeStatus.working')
                }


            }
        }
    }
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
    &.vs-chip-success {
        background: rgba(var(--vs-success), .15);
        color: rgba(var(--vs-success), 1) !important;
        font-weight: 500;
    }

    &.vs-chip-danger {
        background: rgba(var(--vs-danger), .15);
        color: rgba(var(--vs-danger), 1) !important;
        font-weight: 500;
    }
}
</style>
