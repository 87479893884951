<template>
    <div id="page-branch-list">
        <div class="vx-card p-6">
            <div class="vx-row items-center">
                <div class="vx-col md:w-1/2">
                    <TitleScreen></TitleScreen>
                </div>
                <div class="vx-col md:w-1/2">
                    <vs-button
                        class="float-right ml-5"
                        @click="addEmployee"
                        icon="icon-plus"
                        icon-pack="feather"
                        color="primary">
                        {{ $t('button.addNew') }}
                    </vs-button>
                    <vs-button icon="icon-rotate-cw" icon-pack="feather" class="float-right"
                               type="border"
                               @click="UpdateModelFaceBank">{{ $t('button.updateAI') }}
                    </vs-button>
                </div>

            </div>
            <div class="vx-row items-center">
                <div class="vx-col md:w-1/4 sm:w-auto">
                    <label class="vs-input--label">{{ $t('menuOrganization.branch') }}</label>
                    <v-select class="" v-model="selectedOrganizationBranch" :clearable="false"
                              :options="organizationBranchOptions">
                        <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                    </v-select>
                </div>
                <div class="vx-col md:w-3/4">
                    <vs-input class="float-right"
                              v-model="searchQuery"
                              @keypress.enter="updateSearchQuery" :placeholder="$t('button.search')"/>
                </div>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="employeeData"
                         :animateRows="true"
                         :pagination="true"
                         :paginationPageSize="paginationPageSize"
                         :localeText=" {noRowsToShow: this.$t('menuCustomer.noRowsToShow')}"
                         :onGridReady="onGridReady"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <div class="vx-row items-center">
                <div class="vx-col md:w-1/2">
                    <Pagination
                        :itemList="employeeData"
                        :currentPage="currentPage"
                        :paginationPageSize="paginationPageSize"
                        :gridApi="gridApi"
                    />
                </div>
                <div class="vx-col md:w-1/2">
                    <vs-pagination
                        :total="totalPages"
                        :max="maxPageNumbers"
                        v-model="currentPage"
                        @change="loadPageEmployee"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import Pagination from "../../ui-elements/Pagination.vue"
import CellRendererStatus from "./cell-renderer/CellRendererStatus";
import TitleScreen from "../../../layouts/main/TitleScreen";
export default {
    components: {
        AgGridVue,
        vSelect,
        // Cell Renderer
        CellRendererStatus,
        CellRendererActions,
        Pagination,
        TitleScreen
    },
    data() {
        return {
            userInfo: {},
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {
            },
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: this.$t('menuOrganization.employeeCode'),
                    field: 'employeeCode',
                    width: 150
                },
                {
                    headerName: this.$t('menuOrganization.employeeName'),
                    field: 'fullName',
                    width: 200
                },
                {
                    headerName: this.$t('menuOrganization.address'),
                    field: 'fullAddress',
                    width: 200,
                },
                {
                    headerName: this.$t('menuOrganization.phoneNumber'),
                    field: 'phoneNumber',
                    width: 200,
                },
                {
                    headerName: this.$t('menuOrganization.status'),
                    field: 'enabledString',
                    cellRendererFramework: 'CellRendererStatus',
                    width: 200,
                },
                {
                    headerName: this.$t('button.actions'),
                    field: 'actions',
                    width: 150,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions,
                CellRendererStatus,
            },
            employeeData: [],
            organizationBranchOptions: [],
            selectedOrganizationBranchValue: null,
            pageNumber: 0,
            pageSize: 20,
            currentPage: 1,
            totalPages: 0
        }
    },
    created() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.userInfo = userInfo;
        this.initScreen()
        this.$eventBus.$on('i18n', () => {
            this.columnDefs[0].headerName = this.$t('menuOrganization.employeeCode');
            this.columnDefs[1].headerName = this.$t('menuOrganization.employeeName');
            this.columnDefs[2].headerName = this.$t('menuOrganization.address');
            this.columnDefs[3].headerName = this.$t('menuOrganization.phoneNumber');
            this.columnDefs[4].headerName = this.$t('menuOrganization.status');
            this.columnDefs[5].headerName = this.$t('button.actions');
            this.gridOptions.api.setColumnDefs(this.columnDefs);

        })
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateEmployee'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('UPDATE_EMPLOYEE', false);
            }
        }
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        // totalPages() {
        //     if (this.gridApi) return this.gridApi.paginationGetTotalPages();
        //     else return 0
        // },
        // currentPage: {
        //     get() {
        //         if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        //         else return 1
        //     },
        //     set(val) {
        //         this.gridApi.paginationGoToPage(val - 1)
        //     }
        // },
        selectedOrganizationBranch: {
            get() {
                return {
                    label: this.organizationBranchString(this.selectedOrganizationBranchValue),
                    value: this.selectedOrganizationBranchValue
                }
            },
            set(obj) {
                this.selectedOrganizationBranchValue = obj ? obj.value : null;
                if(!this.selectedOrganizationBranchValue) {
                    this.loadEmployee(false)
                } else {
                    if (!this.selectedOrganizationBranchValue) this.selectedOrganizationBranchValue = this.userInfo.organizationBranchId;
                    this.loadEmployee(true)
                }
            }
        }
    },
    methods: {
        loadPageEmployee: function(currentPage) {
            let pageNumber = parseInt(currentPage) ? parseInt(currentPage) - 1 : 0;
            this.pageNumber = pageNumber;
            this.loadEmployee()
        },
        updateSearchQuery(val) {
            // this.gridApi.setQuickFilter(val)
            this.loadEmployee()
        },
        organizationBranchString(id) {
            let organizationBranch = this.organizationBranchOptions.find(x => x.value === id);
            return (organizationBranch && organizationBranch.label) || this.$t('menuOrganization.null');
        },
        addEmployee() {
            let path = this.selectedOrganizationBranchValue ? `/user/add-employee?branch_id=${this.selectedOrganizationBranchValue}` : `/user/add-employee?branch_id=${this.organizationBranchOptions[1].value}`;
            this.$router.push(path).catch(() => {});
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },

        UpdateModelFaceBank() {
            if(this.employeeData.length == 0){
                return this.$vs.notify({
                    text: this.$t('warningMenuOrganization.emptyEmployee'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            this.$vs.loading();
            this.$crm.get('/face-bank-model/version/update').then(() => {
                this.$vs.loading.close();
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
                return this.$vs.notify({
                    text: this.$t('menuOrganization.dataUpdate'),
                    iconPack: 'feather',
                    icon: 'icon-CheckCircleIcon',
                    color: 'success'
                });
            }).catch(() => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: this.$t('warningMenuOrganization.anErr'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },

        initScreen() {
            let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
            if (branchList.length === 0) {
                return this.$vs.notify({
                    text: this.$t('menuOrganization.noBranch'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            this.organizationBranchOptions = branchList.map(item => {
                return {value: item.id, label: item.organizationBranchName}
            });
            this.organizationBranchOptions.unshift({value: null, label: this.$t('menuOrganization.all')});
            let branchId = this.$route.query.branch_id;
            let index = 0;
            if (branchId) {
                index = this.organizationBranchOptions.findIndex(item => item.value == branchId)
            }
            this.selectedOrganizationBranch = this.organizationBranchOptions[index > -1 ? index : 0];

        },
        loadEmployee(stt) {
            this.$vs.loading();
            let employeeSearchDTO = {
                // enabled: null,
                organizationBranchId: stt ? this.selectedOrganizationBranchValue : null,
                organizationId: this.userInfo.organization.id,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                sort: 'id',
                name: this.searchQuery
            }
            this.$crm.post('/employee/find-by-condition', employeeSearchDTO).then((response) => {
                response.data.content.forEach(x => {
                    if (x.enabled) {
                        x.enabledString = this.$t('employeeStatus.working');
                    } else {
                        x.enabledString = this.$t('employeeStatus.offWork');
                    }
                })
                
                this.employeeData = response.data.content
                this.employeeData.sort((a, b) => b.id > a.id)
                this.totalPages = response.data.totalPages
                this.$vs.loading.close();
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        }
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>

<style scoped>
.f-r {
    float: right;
}
</style>
